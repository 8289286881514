import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ContactView from '@/views/ContactView.vue'
import AboutView from '@/views/AboutView.vue'
import CoursesView from '@/views/CoursesView.vue'
import GalleryView from '@/views/GalleryView.vue'
import CourseDetailsView from '@/views/CourseDetailsView.vue'
import LoginView from '@/views/LoginView.vue'
import Dashboard from '@/views/Admin/DashboardView.vue'
import AddListingView from '@/views/Admin/AddListingView.vue'
import AdminCoursesView from '@/views/Admin/AdminCoursesView.vue'
import ErrorView from '@/views/ErrorView.vue'
import UserProfileView from '@/views/UserProfileView.vue'
import AddTeacherView from '@/views/Admin/AddTeacherView.vue'
import Portfolio from '@/views/Portfolio.vue'
import Blogs from '@/views/Blogs.vue'
import Events from '@/views/Events.vue'

const routes = [
  {
    path: '/',
    name: 'homeview',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/courses',
    name: 'courses',
    component: CoursesView
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: GalleryView
  },
  {
    path: '/coursedetails/:id',
    name: 'courseDetails',
    component: CourseDetailsView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/addlisting',
    name: 'addListing',
    component: AddListingView
  },
  {
    path: '/addteacher',
    name: 'addTeacher',
    component: AddTeacherView
  },
  {
    path: '/all-courses',
    name: 'AdminCourses',
    component: AdminCoursesView
  },
  {
    path: '/:catchAll(.*)*',
    name: 'error',
    component: ErrorView
  },
  {
    path: '/register',
    name: 'userProfile',
    component: UserProfileView
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: Portfolio
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: Blogs
  },
  {
    path: '/events',
    name: 'events',
    component: Events
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
