<template>
	<!-- Footer ==== -->
	<footer>
		<div class="footer-top">
			<div class="pt-exebar">
				<div class="container">

				</div>
			</div>
			<div class="container">
				<div class="row">
					
					<div class="col-12 col-lg-5 col-md-7 col-sm-12">
						<div class="row">
							<div class="col-4 col-lg-4 col-md-4 col-sm-4">
								<div class="widget footer_widget">
									<h5 class="footer-title">Company</h5>
									<ul>
										<li><a href="index.html">Home</a></li>
										<li><a href="about-1.html">About</a></li>
										<li><a href="faq-1.html">FAQs</a></li>
										<li><a href="contact-1.html">Contact</a></li>
									</ul>
								</div>
							</div>
							<div class="col-4 col-lg-4 col-md-4 col-sm-4">
								<div class="widget footer_widget">
									<h5 class="footer-title">Get In Touch</h5>
									<ul>
										<li><a href="http://educhamp.themetrades.com/admin/index.html">Dashboard</a>
										</li>
										<li><a href="blog-classic-grid.html">Blog</a></li>
										<li><a href="portfolio.html">Portfolio</a></li>
										<li><a href="event.html">Event</a></li>
									</ul>
								</div>
							</div>
							<div class="col-4 col-lg-4 col-md-4 col-sm-4">
								<div class="widget footer_widget">
									<h5 class="footer-title">Courses</h5>
									<ul>
										<li><a href="courses.html">Courses</a></li>
										<li><a href="courses-details.html">Details</a></li>
										<li><a href="membership.html">Membership</a></li>
										<li><a href="profile.html">Profile</a></li>
									</ul>
								</div>
							</div>

						</div>
					</div>
					<div class="wrapper">
						<h5 class="footer-title">Follow Us</h5>
						<a href="https://web.facebook.com/profile.php?id=61559182143163" class="icon facebook">
							<div class="tooltip">Facebook</div>
							<span><i class="fab fa-facebook-f"></i></span>
						</a>
						<a href="https://twitter.com/naascampus" class="icon twitter">
							<div class="tooltip">Twitter</div>
							<span><i class="fab fa-twitter"></i></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 text-center"> <a target="_blank"
							href="https://www.templateshub.net">Solution Base Company</a></div>
				</div>
			</div>
		</div>
	</footer>
	<!-- Footer END ==== -->
</template>
<script>
export default {
	name: 'Footer'
}
</script>
<style lang="css" scoped src="../assets/css/Client/style.css"></style>
<style lang="css" scoped src="../assets/css/Client/color/color-1.css"></style>