<template>
    <header class="header rs-nav header-transparent">
			<div class="sticky-header navbar-expand-lg is-fixed">
				<div class="menu-bar clearfix">
					<div class="container clearfix">
						<!-- Header Logo ==== -->
						<div class="menu-logo">
							<a href="/"><img src="../../public/assets/Client/images/logo-white.png" alt=""></a>
						</div>
						<!-- Mobile Nav Button ==== -->
						<button class="navbar-toggler collapsed menuicon justify-content-end" type="button"
							data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown"
							aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>
						<!-- Author Nav ==== -->
						<div class="secondary-menu">
							<div class="secondary-inner">
								<ul>
									<li><a href="https://web.facebook.com/profile.php?id=61559182143163" target="_blank" class="btn-link"><i class="fa fa-facebook"></i></a></li>
									<li><a href="https://twitter.com/naascampus" class="btn-link" target="_blank"><i class="fa fa-twitter"></i></a></li>
									<!-- Search Button ==== -->
									<li class="search-btn"><button id="quik-search-btn" type="button"
											class="btn-link"><i class="fa fa-search"></i></button></li>
								</ul>
							</div>
						</div>
						<!-- Search Box ==== -->
						<div class="nav-search-bar">
							<form action="#">
								<input name="search" value="" type="text" class="form-control"
									placeholder="Type to search">
								<span><i class="ti-search"></i></span>
							</form>
							<span id="search-remove"><i class="ti-close"></i></span>
						</div>
						<!-- Navigation Menu ==== -->
						<div class="menu-links navbar-collapse collapse justify-content-start" id="menuDropdown">
							<div class="menu-logo">
								<a href=""><img src="../../public/assets/Client/images/logo.png" alt=""></a>
							</div>
							<ul class="nav navbar-nav">
								<li class="active"><a href="/">Home</a>

								</li>
								<li><a>Pages <i class="fa fa-chevron-down"></i></a>
									<ul class="sub-menu">
										<li>
											<router-link to="/about">About</router-link>
										</li>
										<li>
											<router-link to="/contact">Contact</router-link>
										</li>
                                        <li>
											<a href="/portfolio">Portfolio</a>
										</li>
										
									</ul>
								</li>
								<li><a href="/courses">Courses</a></li>
								<li><a href="/blogs">Blog</a>
								
								</li>
								<li><a href="/events">Events</a>
								</li>
								<li><a href="https://register.naascampus.com/">Register</a>
								</li>
								
								<!-- <li><a><router-link to="/register">Student Register</router-link></a>
								</li> -->
							</ul>
							<div class="nav-social-link">
								<a href="javascript:;"><i class="fa fa-facebook"></i></a>
								<a href="javascript:;"><i class="fa fa-google-plus"></i></a>
								<a href="javascript:;"><i class="fa fa-linkedin"></i></a>
							</div>
						</div>
						<!-- Navigation Menu END ==== -->
					</div>
				</div>
			</div>
	</header>
</template>

<script>
export default {
	name: 'Header'
}
</script>