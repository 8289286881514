<template>
    <Header/>
     <!-- Content -->
     <div class="page-content bg-white">
        <!-- inner page banner -->
        <div class="page-banner ovbl-dark" style="background-image:url(../../public/assets/images/banner/banner3.jpg);">
            <div class="container">
                <div class="page-banner-entry">
                    <h1 class="text-white">Our Courses</h1>
				 </div>
            </div>
        </div>
		<!-- Breadcrumb row -->
		<div class="breadcrumb-row">
			<div class="container">
				<ul class="list-inline">
					<li><a href="#">Home</a></li>
					<li>Our Courses</li>
				</ul>
			</div>
		</div>
		<!-- Breadcrumb row END -->
        <!-- inner page banner END -->
		<div class="content-block">
            <!-- About Us -->
			<div class="section-area section-sp1">
                <div class="container">
					 <div class="row">
						<div class="col-lg-3 col-md-4 col-sm-12 m-b30">
							<div class="widget courses-search-bx placeani">
								<div class="form-group">
									<div class="input-group">
										<input name="dzName" type="text" required class="form-control" v-model="searchKey" placeholder="Search Courses">
									</div>
								</div>
							</div>
							<div class="widget">
								<a href="/coursedetails"><img src="../../public/assets/Client/images/adv/adv.jpg" alt=""/></a>
							</div>
						</div>
						<div class="col-lg-9 col-md-8 col-sm-12">
							<div class="row">
								<div class="col-md-6 col-lg-4 col-sm-6 m-b30" v-for="(course) in filterCourses" :key="course.id">
									<div class="cours-bx">
										<div class="action-box">
											<img src="../../public/assets/Client/images/courses/pic1.jpg" alt="">
											<a @click="navigateToCourse(course.id)" class="btn">Read More</a>
										</div>
										<div class="info-bx text-center">
											<h5><a @click="navigateToCourse(course.id)">{{ course.courseTitle }}</a></h5>
											<span>Programming</span>
										</div>
									</div>
								</div>
								<div class="col-lg-12 m-b20">
									<div class="pagination-bx rounded-sm gray clearfix">
										<ul class="pagination">
											<li class="previous"><a href="#"><i class="ti-arrow-left"></i> Prev</a></li>
											<li class="active"><a href="#">1</a></li>
											<li><a href="#">2</a></li>
											<li><a href="#">3</a></li>
											<li class="next"><a href="#">Next <i class="ti-arrow-right"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </div>
		<!-- contact area END -->
		
    </div>
    <!-- Content END-->
    <Footer/>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import { db, getDocs, collection, doc } from '@/includes/firebase'

export default {
  name: 'CoursesView',
  components: {
    Header,
    Footer
  },
  data() {
	return {
		courses: [],
		searchKey: "",
	}
  },
  methods: {
    navigateToCourse(courseId) {
      this.$router.push(`/coursedetails/${courseId}`);
    },
	async getCourses() {
		const querySnapshot = await getDocs(collection(db, "courses"));
    	querySnapshot.forEach((doc) => {
		this.courses.push({
			id: doc.id,
			...doc.data()
			});
    	});
	}
},
computed: {
	filterCourses() {
		return this.courses.filter((course) => {
			return course.courseTitle.toLowerCase().match(this.searchKey.toLowerCase());
		})
	}
},
beforeMount() {
    this.getCourses()
  },
};
</script>