import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { collection, query, where, doc, getDocs, getDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDhNz63QWsqpgGaapkT3S8Pj9zE0oe66Tc",
    authDomain: "naas-campus-01.firebaseapp.com",
    projectId: "naas-campus-01",
    storageBucket: "naas-campus-01.appspot.com",
    messagingSenderId: "913097532125",
    appId: "1:913097532125:web:c4d9964e393040cfdcb134",
    measurementId: "G-5YJ9DM1RMV"
};

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
const auth = getAuth(firebase);

export {
    firebase,
    db,
    auth,
    getDocs,
    collection,
    doc,
    getDoc
}