import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
import './includes/firebase'
import '@/assets/loadAssets'
import VeeValidatePlugin from './includes/validation'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VeeValidatePlugin);
app.use(TawkMessengerVue, {
    propertyId: '65f283599131ed19d979ae36',
    widgetId: '1hotl26ff'
});

app.mount('#app')
