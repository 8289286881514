<template>
    <Header />
    <!--Main container start -->
	<main class="ttr-wrapper">
		<div class="container-fluid">
			<div class="db-breadcrumb">
				<h4 class="breadcrumb-title">Student Registration</h4>
				<ul class="db-breadcrumb-list">
					<li><a href="#"><i class="fa fa-home"></i>Home</a></li>
					<li>Student Registration</li>
				</ul>
			</div>	
			<div class="row">
				<!-- Your Profile Views Chart -->
				<div class="col-lg-12 m-b30">
					<div class="widget-box">
						<div class="wc-title">
							<h4>Student Registration</h4>
						</div>
						<div class="widget-inner">
							<form class="edit-profile m-b30">
								<div class="">
									<div class="form-group row">
										<div class="col-sm-10  ml-auto">
											<h3>1. Personal Details</h3>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">First Name</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="Mark Andre">
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Last Name</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="Mark Andre">
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Name With Initials</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="Mark Andre">
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Occupation</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="CTO">
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Phone No.</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="+120 012345 6789">
										</div>
									</div>
									
									<div class="seperator"></div>
									
									<div class="form-group row">
										<div class="col-sm-10 ml-auto">
											<h3>2. Address</h3>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Address</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="5-S2-20 Dummy City, UK">
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">City</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="US">
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">State</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="California">
										</div>
									</div>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Postcode</label>
										<div class="col-sm-7">
											<input class="form-control" type="text" value="000702">
										</div>
									</div>

									<div class="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x"></div>

								</div>
								
								<div class="seperator"></div>
									
									<div class="form-group row">
										<div class="col-sm-10 ml-auto">
											<h3>3. Qualifications</h3>
										</div>
									</div>

									<div class="">
									<div class="">
										<div class="row">
											<div class="col-sm-2">
											</div>
											<div class="col-sm-7">
												<button type="submit" class="btn">Register</button>
												<button type="reset" class="btn-secondry">Reset</button>
												<button type="reset" class="btn-secondry" @click="fdata">fData</button>

												<div>{{ status }}</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<!-- Your Profile Views Chart END-->
			</div>
		</div>
	</main>
	<div class="ttr-overlay"></div>
    <Footer />
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'UserProfileView',
  components: {
    Header,
    Footer
  },
  data() {
	return {
		status: ''
	}
  },
  methods: {
	generateVerificationCode(length = 6) {
  const characters = '0123456789';
  let code = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters[randomIndex];
  }
  return code;
},
	async fdata() {
	let code = this.generateVerificationCode();
	console.log(code)
	const response = await fetch('https://sms-6816.twil.io/sms', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        'Body': code
    })
});

     const data = await response.json();
     this.status = data.status;
	}
  }
}
</script>

<style lang="css" scoped src="../assets/css/Admin/css/typography.css"></style>
<style lang="css" scoped src="../assets/css/Admin/css/shortcodes/shortcodes.css"></style>
<style lang="css" scoped src="../assets/css/Admin/css/dashboard.css"></style>
<style lang="css" scoped src="../assets/css/Admin/css/style.css"></style>
<style lang="css" scoped src="../assets/css/Admin/css/color/color-1.css"></style>

