<script setup>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

</script>


<template>
	<Header />
	<!-- Content -->
	<div class="page-content bg-white">
		<!-- inner page banner -->
		<div class="page-banner ovbl-dark"
			style="background-image:url(../../public/assets/images/banner/banner1.jpg);">
			<div class="container">
				<div class="page-banner-entry">
					<h1 class="text-white">NAAS Campus Blogs</h1>
				</div>
			</div>
		</div>
		<div class="content-block" >
			<div class="section-area section-sp1">
				<div class="container">
					<div class="row">
						<!-- Left part start -->
						<div class="col-md-7 col-lg-8 col-xl-8" >
							<div class="blogs">
								<div class="loading" v-if="loading">Loading...</div>
								<div v-if="error" class="error">
									{{ error }}
								</div>
								<div >
									<div class="recent-news blog-lg m-b40" v-for="b in blogs">
										<div class="action-box blog-lg">
											<img src="(b.Image)" alt=" " />
										</div>
										<div class="info-bx">
											 
											<h5 class="post-title"><a href="blog-details.html">{{ b.Title }}</a></h5>
											<p>{{ b.Description}}</p>
											
											<!-- <div class="post-extra">
												<a href="#" class="btn-link">READ MORE</a>
												<a href="#" class="comments-bx"><i class="fa fa-comments-o"></i>78
													Comment</a>
											</div> -->
										</div>
									</div>
								</div>
							</div>
							<!-- Pagination start -->
							<div class="pagination-bx rounded-sm gray clearfix">
								<ul class="pagination">
									<li class="previous"><a href="#"><i class="ti-arrow-left"></i> Prev</a></li>
									<li class="active"><a href="#">1</a></li>
									<li><a href="#">2</a></li>
									<li><a href="#">3</a></li>
									<li class="next"><a href="#">Next <i class="ti-arrow-right"></i></a></li>
								</ul>
							</div>
							<!-- Pagination END -->
						</div>
						<!-- Left part END -->
						<!-- Side bar start -->
						<div class="col-md-5 col-lg-4 col-xl-4 sticky-top">
							<aside class="side-bar sticky-top">
								<div class="widget">
									<h6 class="widget-title">Search</h6>
									<div class="search-bx style-1">
										<form role="search" method="post">
											<div class="input-group">
												<input name="text" class="form-control" placeholder="Enter your keywords..."
													type="text">
												<span class="input-group-btn">
													<button type="submit" class="fa fa-search text-primary"></button>
												</span>
											</div>
										</form>
									</div>
								</div>
								<div class="widget recent-posts-entry">
									<h6 class="widget-title">Recent Posts</h6>
									<div class="widget-post-bx">
										<div class="widget-post clearfix">
											<div class="ttr-post-media"> <img
													src="../../public/assets/Client/images/gallery/pic1.jpg"
													width="200" height="143" alt=""> </div>
											<div class="ttr-post-info">
												<div class="ttr-post-header">
													<h6 class="post-title"><a href="blog-details.html">This Story Behind
															Education Will Haunt You Forever.</a></h6>
												</div>
												<ul class="media-post">
													<li><a href="#"><i class="fa fa-calendar"></i>Oct 23 2019</a></li>
													<li><a href="#"><i class="fa fa-comments-o"></i>15 Comment</a></li>
												</ul>
											</div>
										</div>
										<div class="widget-post clearfix">
											<div class="ttr-post-media"> <img
													src="../../public/assets/Client/images/gallery/pic2.jpg"
													width="200" height="160" alt=""> </div>
											<div class="ttr-post-info">
												<div class="ttr-post-header">
													<h6 class="post-title"><a href="blog-details.html">What Will Education
															Be Like In The Next 50 Years?</a></h6>
												</div>
												<ul class="media-post">
													<li><a href="#"><i class="fa fa-calendar"></i>May 14 2019</a></li>
													<li><a href="#"><i class="fa fa-comments-o"></i>23 Comment</a></li>
												</ul>
											</div>
										</div>
										<div class="widget-post clearfix">
											<div class="ttr-post-media"> <img
													src="../../public/assets/Client/images/gallery/pic3.jpg"
													width="200" height="160" alt=""> </div>
											<div class="ttr-post-info">
												<div class="ttr-post-header">
													<h6 class="post-title"><a href="blog-details.html">Eliminate Your Fears
															And Doubts About Education.</a></h6>
												</div>
												<ul class="media-post">
													<li><a href="#"><i class="fa fa-calendar"></i>June 12 2019</a></li>
													<li><a href="#"><i class="fa fa-comments-o"></i>27 Comment</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div class="widget widget-newslatter">
									<h6 class="widget-title">Newsletter</h6>
									<div class="news-box">
										<p>Enter your e-mail and subscribe to our newsletter.</p>
										<form class="subscription-form"
											action="http://educhamp.themetrades.com/demo/../../public/assets/script/mailchamp.php"
											method="post">
											<div class="ajax-message"></div>
											<div class="input-group">
												<input name="dzEmail" required="required" type="email" class="form-control"
													placeholder="Your Email Address" />
												<button name="submit" value="Submit" type="submit"
													class="btn black radius-no">
													<i class="fa fa-paper-plane-o"></i>
												</button>
											</div>
										</form>
									</div>
								</div>
								<div class="widget widget_gallery gallery-grid-4">
									<h6 class="widget-title">Our Gallery</h6>
									<ul>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic2.jpg"
														alt=""></a></div>
										</li>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic1.jpg"
														alt=""></a></div>
										</li>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic5.jpg"
														alt=""></a></div>
										</li>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic7.jpg"
														alt=""></a></div>
										</li>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic8.jpg"
														alt=""></a></div>
										</li>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic9.jpg"
														alt=""></a></div>
										</li>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic3.jpg"
														alt=""></a></div>
										</li>
										<li>
											<div><a href="#"><img src="../../public/assets/Client/images/gallery/pic4.jpg"
														alt=""></a></div>
										</li>
									</ul>
								</div>
								<div class="widget widget_tag_cloud">
									<h6 class="widget-title">Tags</h6>
									<div class="tagcloud">
										<a href="#">Design</a>
										<a href="#">User interface</a>
										<a href="#">SEO</a>
										<a href="#">WordPress</a>
										<a href="#">Development</a>
										<a href="#">Joomla</a>
										<a href="#">Design</a>
										<a href="#">User interface</a>
										<a href="#">SEO</a>
										<a href="#">WordPress</a>
										<a href="#">Development</a>
										<a href="#">Joomla</a>
										<a href="#">Design</a>
										<a href="#">User interface</a>
										<a href="#">SEO</a>
										<a href="#">WordPress</a>
										<a href="#">Development</a>
										<a href="#">Joomla</a>
									</div>
								</div>
							</aside>
						</div>
						<!-- Side bar END -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Content END-->
	
	<Footer />
</template>


<script>
import Footer from '../components/Footer.vue'
import { db, getDocs, collection } from '@/includes/firebase'

export default {
  components: {
    Footer
  },
  data() {
    return {
      blogs: [] // Initialize an empty array to store fetched blogs
    }
  },
  async created() {
    const colRef = collection(db, 'blogs')
    try {
      const snapshot = await getDocs(colRef)
      snapshot.forEach(doc => {
        // Push each blog document into the blogs array
        this.blogs.push({ ...doc.data(), id: doc.id })
      })
    } catch (error) {
      console.error('Error fetching blogs:', error.message)
    }
	
  }
  
}
</script>