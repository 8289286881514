<template>
    <Header/>
    <!-- Inner Content Box ==== -->
    <div class="page-content">
        <!-- Page Heading Box ==== -->
        <div class="page-banner ovbl-dark" style="background-image:url(../../public/assets/images/banner/banner2.jpg);">
            <div class="container">
                <div class="page-banner-entry">
                    <h1 class="text-white">About of NAAS University</h1>
				 </div>
            </div>
        </div>
		<!-- Page Heading Box END ==== -->
		<!-- Page Content Box ==== -->
		<div class="content-block">
            <!-- About Us ==== -->
			<div class="section-area section-sp1">
                <div class="container">
					 <div class="row">
						<div class="col-lg-3 col-md-6 col-sm-6 m-b30">
							<div class="feature-container">
								<div class="feature-md text-white m-b20">
									<a href="#" class="icon-cell"><img src="../../public/assets/Client/images/icon/icon1.png" alt=""/></a> 
								</div>
								<div class="icon-content">
									<h5 class="ttr-tilte">Our Philosophy</h5>
									<p>At our campus, we believe in fostering a culture of curiosity, critical thinking, and collaboration, empowering students to become lifelong learners and global citizens.</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-6 m-b30">
							<div class="feature-container">
								<div class="feature-md text-white m-b20">
									<a href="#" class="icon-cell"><img src="../../public/assets/Client/images/icon/icon2.png" alt=""/></a> 
								</div>
								<div class="icon-content">
									<h5 class="ttr-tilte">Kingster's Principle</h5>
									<p>We uphold the principles of integrity, excellence, inclusivity, and innovation, guiding students towards personal growth and academic success.</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-6 m-b30">
							<div class="feature-container">
								<div class="feature-md text-white m-b20">
									<a href="#" class="icon-cell"><img src="../../public/assets/Client/images/icon/icon3.png" alt=""/></a> 
								</div>
								<div class="icon-content">
									<h5 class="ttr-tilte">Key Of Success</h5>
									<p>The key to success lies in dedication, resilience, continuous learning, and seizing opportunities. At our campus, we empower students to unlock their full potential.</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-6 m-b30">
							<div class="feature-container">
								<div class="feature-md text-white m-b20">
									<a href="#" class="icon-cell"><img src="../../public/assets/Client/images/icon/icon4.png" alt=""/></a> 
								</div>
								<div class="icon-content">
									<h5 class="ttr-tilte">Our Vision</h5>
									<p>Our vision is to cultivate a dynamic learning community where innovation thrives, minds are inspired, and every individual achieves their fullest potential.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- About Us END ==== -->
            <!-- Our Story ==== -->
			<div class="section-area bg-gray section-sp1 our-story">
				<div class="container">
					<div class="row align-items-center d-flex">
						<div class="col-lg-5 col-md-12 heading-bx">
							<h2 class="m-b10">Our Story</h2>
							<h5 class="fw4 m-b10">NEOSYS ACCADEMY OF APPLIED STUDIES</h5>
							<p>Born from a vision of educational excellence, our campus is a vibrant tapestry of dreams realized. With a commitment to fostering creativity, critical thinking, and community engagement, we embark on a journey of transformation. From humble beginnings to a beacon of academic prowess, our story is one of perseverance, innovation, and unwavering dedication to student success. Together, we shape futures, empower minds, and leave an indelible mark on the world.</p>
						</div>
						<div class="col-lg-7 col-md-12 heading-bx p-lr">
							<div class="video-bx">
								<img src="../../public/assets/Client/images/about/pic1.jpg" alt=""/>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Our Story END ==== -->
			<!-- Our Status ==== -->
			<div class="section-area content-inner section-sp1">
                <div class="container">
                    <div class="section-content">
                         <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
                                    <div class="text-primary">
										<span class="counter">10</span><span>+</span>
									</div>
									<span class="counter-text">ACADEMIC PROGRAMS</span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
									<div class="text-black">
										<span class="counter">250</span><span>+</span>
									</div>
									<span class="counter-text">Students</span>
								</div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
									<div class="text-primary">
										<span class="counter">75</span><span>+</span>
									</div>
									<span class="counter-text">Research</span>
								</div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
									<div class="text-black">
										<span class="counter">07</span><span>+</span>
									</div>
									<span class="counter-text">Our Branches</span>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<!-- Our Status END ==== -->
			
			
		</div>
		<!-- Page Content Box END ==== -->
    </div>
    <!-- Inner Content Box END ==== -->
    <Footer/>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'AboutView',
  components: {
    Header,
    Footer
  },
};
</script>