<template>
    <Header />
    <div class="page-content bg-white">
        <!-- Main Slider -->
        <div class="rev-slider">
            <div id="rev_slider_486_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container"
                data-alias="news-gallery36" data-source="gallery"
                style="margin:0px auto;background-color:#ffffff;padding:0px;margin-top:0px;margin-bottom:0px;">
                <!-- START REVOLUTION SLIDER 5.3.0.2 fullwidth mode -->
                <div id="rev_slider_486_1" class="rev_slider fullwidthabanner" style="display:none;"
                    data-version="5.3.0.2">
                    <ul> <!-- SLIDE  -->
                        <li data-index="rs-100" data-transition="parallaxvertical" data-slotamount="default"
                            data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                            data-easeout="default" data-masterspeed="default" data-thumb="error-404.html"
                            data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                            data-saveperformance="off" data-title="A STUDY ON HAPPINESS" data-param1="" data-param2=""
                            data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9="" data-param10=""
                            data-description="Science says that Women are generally happier">
                            <!-- MAIN IMAGE -->
                            <img src="../../public/assets/Client/images/slider/1.jpg" alt=""
                                data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                data-bgparallax="10" class="rev-slidebg" data-no-retina />

                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-100-layer-1"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5;background-color:rgba(2, 0, 11, 0.80);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption Newspaper-Title   tp-resizeme" id="slide-100-layer-2"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['250','250','250','240']"
                                data-fontsize="['50','50','50','30']" data-lineheight="['55','55','55','35']"
                                data-width="full" data-height="none" data-whitespace="normal" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; font-family:rubik; font-weight:700; text-align:center; white-space: normal;">
                                NAAS CAMPUS<br>Neosys Academy of Applied Studies
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-3"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['210','210','210','210']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; white-space: nowrap; color:#fff; font-family:rubik; font-size:18px; font-weight:400;">
                                Better Education For A Better
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-4"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['320','320','320','290']"
                                data-width="['800','800','700','420']" data-height="['100','100','100','120']"
                                data-whitespace="unset" data-type="text" data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; text-transform:capitalize; white-space: unset; color:#fff; font-family:rubik; font-size:18px; line-height:28px; font-weight:400; margin-top: 50px">
                                "Unlock your potential at our applied studies campus. Embrace challenges, pursue
                                excellence, and forge your path to success with determination and passion."
                            </div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption Newspaper-Button rev-btn" id="slide-100-layer-6"
                                data-x="['center','center','center','center']" data-hoffset="['-90','-80','-75','-90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; margin-left:150px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; border-radius:30px;">
                                <router-link to="/contact">CONTACT US</router-link></div>
                        </li>
                        <li data-index="rs-200" data-transition="parallaxvertical" data-slotamount="default"
                            data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                            data-easeout="default" data-masterspeed="default" data-thumb="error-404.html"
                            data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                            data-saveperformance="off" data-title="A STUDY ON HAPPINESS" data-param1="" data-param2=""
                            data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9="" data-param10=""
                            data-description="Science says that Women are generally happier">
                            <!-- MAIN IMAGE -->
                            <img src="../../public/assets/Client/images/slider/2.jpg" alt=""
                                data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                data-bgparallax="10" class="rev-slidebg" data-no-retina />

                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-100-layer-1"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5;background-color:rgba(2, 0, 11, 0.80);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption Newspaper-Title   tp-resizeme" id="slide-100-layer-2"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['250','250','250','240']"
                                data-fontsize="['50','50','50','30']" data-lineheight="['55','55','55','35']"
                                data-width="full" data-height="none" data-whitespace="normal" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; font-family:rubik; font-weight:700; text-align:center; white-space: normal;">
                                NAAS CAMPUS<br>Neosys Academy of Applied Studies
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-3"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['210','210','210','210']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; white-space: nowrap; color:#fff; font-family:rubik; font-size:18px; font-weight:400;">
                                Better Education For A Better
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-4"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['320','320','320','290']"
                                data-width="['800','800','700','420']" data-height="['100','100','100','120']"
                                data-whitespace="unset" data-type="text" data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; text-transform:capitalize; white-space: unset; color:#fff; font-family:rubik; font-size:18px; line-height:28px; font-weight:400; margin-top: 50px">
                                "Unlock your potential at our applied studies campus. Embrace challenges, pursue
                                excellence, and forge your path to success with determination and passion."
                            </div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption Newspaper-Button rev-btn" id="slide-100-layer-6"
                                data-x="['center','center','center','center']" data-hoffset="['-90','-80','-75','-90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; border-radius:30px;">
                                <router-link to="/contact">CONTACT US</router-link></div>
                        </li>
                        <li data-index="rs-300" data-transition="parallaxvertical" data-slotamount="default"
                            data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                            data-easeout="default" data-masterspeed="default" data-thumb="error-404.html"
                            data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                            data-saveperformance="off" data-title="A STUDY ON HAPPINESS" data-param1="" data-param2=""
                            data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9="" data-param10=""
                            data-description="Science says that Women are generally happier">
                            <!-- MAIN IMAGE -->
                            <img src="../../public/assets/Client/images/slider/3.jpg" alt=""
                                data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                data-bgparallax="10" class="rev-slidebg" data-no-retina />

                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-100-layer-1"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5;background-color:rgba(2, 0, 11, 0.80);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption Newspaper-Title   tp-resizeme" id="slide-100-layer-2"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['250','250','250','240']"
                                data-fontsize="['50','50','50','30']" data-lineheight="['55','55','55','35']"
                                data-width="full" data-height="none" data-whitespace="normal" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; font-family:rubik; font-weight:700; text-align:center; white-space: normal;">
                                NAAS CAMPUS<br>Neosys Academy of Applied Studies
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-3"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['210','210','210','210']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; white-space: nowrap; color:#fff; font-family:rubik; font-size:18px; font-weight:400;">
                                Better Education For A Better
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-4"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['320','320','320','290']"
                                data-width="['800','800','700','420']" data-height="['100','100','100','120']"
                                data-whitespace="unset" data-type="text" data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; text-transform:capitalize; white-space: unset; color:#fff; font-family:rubik; font-size:18px; line-height:28px; font-weight:400; margin-top: 50px">
                                "Unlock your potential at our applied studies campus. Embrace challenges, pursue
                                excellence, and forge your path to success with determination and passion."
                            </div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption Newspaper-Button rev-btn" id="slide-100-layer-6"
                                data-x="['center','center','center','center']" data-hoffset="['-90','-80','-75','-90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; border-radius:30px;">
                                <router-link to="/contact">CONTACT US</router-link></div>
                        </li>
                        <li data-index="rs-400" data-transition="parallaxvertical" data-slotamount="default"
                            data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                            data-easeout="default" data-masterspeed="default" data-thumb="error-404.html"
                            data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                            data-saveperformance="off" data-title="A STUDY ON HAPPINESS" data-param1="" data-param2=""
                            data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9="" data-param10=""
                            data-description="Science says that Women are generally happier">
                            <!-- MAIN IMAGE -->
                            <img src="../../public/assets/Client/images/slider/4.jpg" alt=""
                                data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                data-bgparallax="10" class="rev-slidebg" data-no-retina />

                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-100-layer-1"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5;background-color:rgba(2, 0, 11, 0.80);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption Newspaper-Title   tp-resizeme" id="slide-100-layer-2"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['250','250','250','240']"
                                data-fontsize="['50','50','50','30']" data-lineheight="['55','55','55','35']"
                                data-width="full" data-height="none" data-whitespace="normal" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; font-family:rubik; font-weight:700; text-align:center; white-space: normal;">
                                NAAS CAMPUS<br>Neosys Academy of Applied Studies
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-3"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['210','210','210','210']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; white-space: nowrap; color:#fff; font-family:rubik; font-size:18px; font-weight:400;">
                                Better Education For A Better
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-4"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['320','320','320','290']"
                                data-width="['800','800','700','420']" data-height="['100','100','100','120']"
                                data-whitespace="unset" data-type="text" data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; text-transform:capitalize; white-space: unset; color:#fff; font-family:rubik; font-size:18px; line-height:28px; font-weight:400; margin-top: 50px">
                                "Unlock your potential at our applied studies campus. Embrace challenges, pursue
                                excellence, and forge your path to success with determination and passion."
                            </div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption Newspaper-Button rev-btn" id="slide-100-layer-6"
                                data-x="['center','center','center','center']" data-hoffset="['-90','-80','-75','-90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; border-radius:30px;">
                                <router-link to="/contact">CONTACT US</router-link></div>
                        </li>
                        <li data-index="rs-500" data-transition="parallaxvertical" data-slotamount="default"
                            data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                            data-easeout="default" data-masterspeed="default" data-thumb="error-404.html"
                            data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                            data-saveperformance="off" data-title="A STUDY ON HAPPINESS" data-param1="" data-param2=""
                            data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9="" data-param10=""
                            data-description="Science says that Women are generally happier">
                            <!-- MAIN IMAGE -->
                            <img src="../../public/assets/Client/images/slider/5.jpg" alt=""
                                data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                data-bgparallax="10" class="rev-slidebg" data-no-retina />

                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-100-layer-1"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5;background-color:rgba(2, 0, 11, 0.80);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption Newspaper-Title   tp-resizeme" id="slide-100-layer-2"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['250','250','250','240']"
                                data-fontsize="['50','50','50','30']" data-lineheight="['55','55','55','35']"
                                data-width="full" data-height="none" data-whitespace="normal" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; font-family:rubik; font-weight:700; text-align:center; white-space: normal;">
                                NAAS CAMPUS<br>Neosys Academy of Applied Studies
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-3"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['210','210','210','210']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; white-space: nowrap; color:#fff; font-family:rubik; font-size:18px; font-weight:400;">
                                Better Education For A Better
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-4"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['320','320','320','290']"
                                data-width="['800','800','700','420']" data-height="['100','100','100','120']"
                                data-whitespace="unset" data-type="text" data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; text-transform:capitalize; white-space: unset; color:#fff; font-family:rubik; font-size:18px; line-height:28px; font-weight:400; margin-top: 50px">
                                "Unlock your potential at our applied studies campus. Embrace challenges, pursue
                                excellence, and forge your path to success with determination and passion."
                            </div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption Newspaper-Button rev-btn" id="slide-100-layer-6"
                                data-x="['center','center','center','center']" data-hoffset="['-90','-80','-75','-90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; border-radius:30px;">
                                <router-link to="/contact">CONTACT US</router-link></div>
                        </li>
                        <li data-index="rs-600" data-transition="parallaxvertical" data-slotamount="default"
                            data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                            data-easeout="default" data-masterspeed="default" data-thumb="error-404.html"
                            data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                            data-saveperformance="off" data-title="A STUDY ON HAPPINESS" data-param1="" data-param2=""
                            data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9="" data-param10=""
                            data-description="Science says that Women are generally happier">
                            <!-- MAIN IMAGE -->
                            <img src="../../public/assets/Client/images/slider/6.jpg" alt=""
                                data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                data-bgparallax="10" class="rev-slidebg" data-no-retina />

                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-100-layer-1"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5;background-color:rgba(2, 0, 11, 0.80);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption Newspaper-Title   tp-resizeme" id="slide-100-layer-2"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['250','250','250','240']"
                                data-fontsize="['50','50','50','30']" data-lineheight="['55','55','55','35']"
                                data-width="full" data-height="none" data-whitespace="normal" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; font-family:rubik; font-weight:700; text-align:center; white-space: normal;">
                                NAAS CAMPUS<br>Neosys Academy of Applied Studies
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-3"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['210','210','210','210']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; white-space: nowrap; color:#fff; font-family:rubik; font-size:18px; font-weight:400;">
                                Better Education For A Better
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-4"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['320','320','320','290']"
                                data-width="['800','800','700','420']" data-height="['100','100','100','120']"
                                data-whitespace="unset" data-type="text" data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; text-transform:capitalize; white-space: unset; color:#fff; font-family:rubik; font-size:18px; line-height:28px; font-weight:400; margin-top: 50px">
                                "Unlock your potential at our applied studies campus. Embrace challenges, pursue
                                excellence, and forge your path to success with determination and passion."
                            </div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption Newspaper-Button rev-btn" id="slide-100-layer-6"
                                data-x="['center','center','center','center']" data-hoffset="['-90','-80','-75','-90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; border-radius:30px;">
                                <router-link to="/contact">CONTACT US</router-link></div>
                        </li>
                        <li data-index="rs-700" data-transition="parallaxvertical" data-slotamount="default"
                            data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default"
                            data-easeout="default" data-masterspeed="default" data-thumb="error-404.html"
                            data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                            data-saveperformance="off" data-title="A STUDY ON HAPPINESS" data-param1="" data-param2=""
                            data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9="" data-param10=""
                            data-description="Science says that Women are generally happier">
                            <!-- MAIN IMAGE -->
                            <img src="../../public/assets/Client/images/slider/7.jpg" alt=""
                                data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                data-bgparallax="10" class="rev-slidebg" data-no-retina />

                            <!-- LAYER NR. 1 -->
                            <div class="tp-caption tp-shape tp-shapewrapper " id="slide-100-layer-1"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                                data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                                data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                                data-frames='[{"from":"opacity:0;","speed":1,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 5;background-color:rgba(2, 0, 11, 0.80);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                            </div>
                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption Newspaper-Title   tp-resizeme" id="slide-100-layer-2"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['250','250','250','240']"
                                data-fontsize="['50','50','50','30']" data-lineheight="['55','55','55','35']"
                                data-width="full" data-height="none" data-whitespace="normal" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[10,10,10,10]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 6; font-family:rubik; font-weight:700; text-align:center; white-space: normal;">
                                NAAS CAMPUS<br>Neosys Academy of Applied Studies
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-3"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['210','210','210','210']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="text"
                                data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; white-space: nowrap; color:#fff; font-family:rubik; font-size:18px; font-weight:400;">
                                Better Education For A Better
                            </div>

                            <!-- LAYER NR. 3 -->
                            <div class="tp-caption Newspaper-Subtitle   tp-resizeme" id="slide-100-layer-4"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['top','top','top','top']" data-voffset="['320','320','320','290']"
                                data-width="['800','800','700','420']" data-height="['100','100','100','120']"
                                data-whitespace="unset" data-type="text" data-responsive_offset="on"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                                data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                data-paddingleft="[0,0,0,0]"
                                style="z-index: 7; text-transform:capitalize; white-space: unset; color:#fff; font-family:rubik; font-size:18px; line-height:28px; font-weight:400; margin-top: 50px">
                                "Unlock your potential at our applied studies campus. Embrace challenges, pursue
                                excellence, and forge your path to success with determination and passion."
                            </div>
                            <!-- LAYER NR. 4 -->
                            <div class="tp-caption Newspaper-Button rev-btn " id="slide-100-layer-5"
                                data-x="['center','center','center','center']" data-hoffset="['90','80','75','90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; background-color:var(--primary) !important; border:0; border-radius:30px; margin-right:5px;">
                                READ MORE </div>
                            <div class="tp-caption Newspaper-Button rev-btn" id="slide-100-layer-6"
                                data-x="['center','center','center','center']" data-hoffset="['-90','-80','-75','-90']"
                                data-y="['top','top','top','top']" data-voffset="['400','400','400','420']"
                                data-width="none" data-height="none" data-whitespace="nowrap" data-type="button"
                                data-responsive_offset="on" data-responsive="off"
                                data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:1px 1px 1px 1px;"}]'
                                data-textAlign="['center','center','center','center']" data-paddingtop="[12,12,12,12]"
                                data-paddingright="[30,35,35,15]" data-paddingbottom="[12,12,12,12]"
                                data-paddingleft="[30,35,35,15]"
                                style="margin-top: 50px; z-index: 8; white-space: nowrap; outline:none;box-shadow:none;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer; border-radius:30px;">
                                CONTACT US</div>
                        </li>
                        <!-- SLIDE  -->
                    </ul>
                </div><!-- END REVOLUTION SLIDER -->
            </div>
        </div>
        <!-- Main Slider -->
        <div class="content-block">

            <!-- Our Services -->
            <div class="section-area content-inner service-info-bx">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <div class="service-bx">
                                <div class="action-box">
                                    <img src="../../public/assets/Client/images/mainpage/academicprogram.jpg" alt="">
                                </div>
                                <div class="info-bx text-center">
                                    <div class="feature-box-sm radius bg-white">
                                        <i class="fa fa-bank text-primary"></i>
                                    </div>
                                    <h4><a href="#">Academic Programs</a></h4>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <div class="service-bx">
                                <div class="action-box">
                                    <img src="../../public/assets/Client/images/our-services/pic2.jpg" alt="">
                                </div>
                                <div class="info-bx text-center">
                                    <div class="feature-box-sm radius bg-white">
                                        <i class="fa fa-book text-primary"></i>
                                    </div>
                                    <h4><a href="#">Career Development</a></h4>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <div class="service-bx">
                                <div class="action-box">
                                    <img src="../../public/assets/Client/images/mainpage/lib.png" alt="">
                                </div>
                                <div class="info-bx text-center">
                                    <div class="feature-box-sm radius bg-white">
                                        <i class="fa fa-book text-primary"></i>
                                    </div>
                                    <h4><a href="#">Book Library & Store</a></h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Our Services END -->

            <!-- Popular Courses -->
            <div class="section-area section-sp2 popular-courses-bx">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 heading-bx left">
                            <h2 class="title-head">Popular <span>Courses</span></h2>
                            <p>Discover our renowned courses designed to ignite your passion, foster innovation, and
                                equip you with the skills needed to thrive in today's competitive world.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="courses-carousel owl-carousel owl-btn-1 col-12 p-lr0">
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/caretaker.jpeg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Care Takers</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/healthassistant.jpg"
                                            alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Health Assistant</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/Wardassistant.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Ward Assistant</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/theatreassistant.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Theater Assistant</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/dentalassistant.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Dental Assistant</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/pharmassistant.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Pharmacist Assistant</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/dispansaryassistant.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Dispensary Assistant</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/hospitalmanag.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Hospitality Management</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/premedi.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Pre-Medicine Programs</a></h5>
                                    </div>

                                </div>
                            </div>
                            <div class="item">
                                <div class="cours-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/mainpage/agricltr.jpg" alt="">
                                        <a href="#" class="btn">Read More</a>
                                    </div>
                                    <div class="info-bx text-center">
                                        <h5><a href="#">Agriculture and Farming</a></h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Popular Courses END -->
            <!-- Form -->
            <div class="section-area section-sp1 ovpr-dark bg-fix online-cours"
                style="background-image:url(../../public/assets/images/background/bg1.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center text-white">
                            <h2>Online Courses To Learn</h2>
                            <h5>Own Your Feature Learning New Skills Online</h5>
                            <form class="cours-search">
                                <div class="input-group">
                                    <input type="text" class="form-control"
                                        placeholder="What do you want to learn today?	">
                                    <div class="input-group-append">
                                        <button class="btn" type="submit">Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Our Status ==== -->
			<div class="section-area content-inner section-sp1">
                <div class="container">
                    <div class="section-content">
                         <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
                                    <div class="text-primary">
										<span class="counter">10</span><span>+</span>
									</div>
									<span class="counter-text">ACADEMIC PROGRAMS</span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
									<div class="text-black">
										<span class="counter">250</span><span>+</span>
									</div>
									<span class="counter-text">Our Students</span>
								</div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
									<div class="text-primary">
										<span class="counter">75</span><span>+</span>
									</div>
									<span class="counter-text">Programm Research</span>
								</div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
                                <div class="counter-style-1">
									<div class="text-black">
										<span class="counter">07</span><span>+</span>
									</div>
									<span class="counter-text">Our Branches</span>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<!-- Our Status END ==== -->
                </div>
            </div>
            <!-- Form END -->
            <!-- <div class="section-area section-sp2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center heading-bx">
                            <h2 class="title-head m-b0">Upcoming <span>Events</span></h2>
                            <p class="m-b0">Upcoming Education Events To Feed Brain. </p>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="upcoming-event-carousel owl-carousel owl-btn-center-lr owl-btn-1 col-12 p-lr0  m-b30">
                            <div class="item">
                                <div class="event-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/event/pic4.jpg" alt="">
                                    </div>
                                    <div class="info-bx d-flex">
                                        <div>
                                            <div class="event-time">
                                                <div class="event-date">29</div>
                                                <div class="event-month">October</div>
                                            </div>
                                        </div>
                                        <div class="event-info">
                                            <h4 class="event-title"><a href="#">Education Autumn Tour 2019</a></h4>
                                            <ul class="media-post">
                                                <li><a href="#"><i class="fa fa-clock-o"></i> 7:00am 8:00am</a></li>
                                                <li><a href="#"><i class="fa fa-map-marker"></i> Berlin, Germany</a>
                                                </li>
                                            </ul>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text
                                                ever since the..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="event-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/event/pic3.jpg" alt="">
                                    </div>
                                    <div class="info-bx d-flex">
                                        <div>
                                            <div class="event-time">
                                                <div class="event-date">29</div>
                                                <div class="event-month">October</div>
                                            </div>
                                        </div>
                                        <div class="event-info">
                                            <h4 class="event-title"><a href="#">Education Autumn Tour 2019</a></h4>
                                            <ul class="media-post">
                                                <li><a href="#"><i class="fa fa-clock-o"></i> 7:00am 8:00am</a></li>
                                                <li><a href="#"><i class="fa fa-map-marker"></i> Berlin, Germany</a>
                                                </li>
                                            </ul>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text
                                                ever since the..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="event-bx">
                                    <div class="action-box">
                                        <img src="../../public/assets/Client/images/event/pic2.jpg" alt="">
                                    </div>
                                    <div class="info-bx d-flex">
                                        <div>
                                            <div class="event-time">
                                                <div class="event-date">29</div>
                                                <div class="event-month">October</div>
                                            </div>
                                        </div>
                                        <div class="event-info">
                                            <h4 class="event-title"><a href="#">Education Autumn Tour 2019</a></h4>
                                            <ul class="media-post">
                                                <li><a href="#"><i class="fa fa-clock-o"></i> 7:00am 8:00am</a></li>
                                                <li><a href="#"><i class="fa fa-map-marker"></i> Berlin, Germany</a>
                                                </li>
                                            </ul>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text
                                                ever since the..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <a href="#" class="btn">View All Event</a>
                    </div>
                </div>
            </div> -->

            <!-- Testimonials -->
            <div class="section-area section-sp2 bg-fix ovbl-dark"
                style="background-image:url(../../public/assets/images/background/bg1.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-white heading-bx left">
                            <h2 class="title-head text-uppercase">Strategic <span>Team</span></h2>
                            <p>Meet our top management team, the designers of our operational expertise. Their skillful
                                and effective work ensures that every part of our organisation runs smoothly. Their
                                commitment to quality sets the stage for a flourishing learning environment. With their
                                careful attention to detail, enjoy an educational experience that is expertly managed!
                            </p>
                        </div>
                    </div>
                    <div class="testimonial-carousel owl-carousel owl-btn-1 col-12 p-lr0">
                        <div class="item">
                            <div class="testimonial-bx">
                                <div class="testimonial-thumb">
                                    <img src="../../public/assets/Client/images/member/ceonihal.jpeg" alt="">
                                </div>
                                <div class="testimonial-info">
                                    <h5 class="name">Mr. Nihal Perera</h5>
                                    <p>-CEO/ Director</p>
                                </div>
                                <div class="testimonial-content">
                                    <p>"As the CEO of Naas Campus, I'm proud to offer diverse educational programs catering to the needs of our students and the demands of today's industries. Our commitment to excellence ensures that graduates are equipped with the skills and knowledge to succeed in their chosen fields. At Naas Campus, we strive to foster a dynamic learning environment that fosters innovation, creativity, and lifelong success."</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimonial-bx">
                                <div class="testimonial-thumb">
                                    <img src="../../public/assets/Client/images/member/Prianka.jpg" alt="">
                                </div>
                                <div class="testimonial-info">
                                    <h5 class="name">Mrs Prianka Mudelige</h5>
                                    <p>Director - Global Synergy Consultation Services.<br>Working partner with NAASCAMPUS</p>
                                </div>
                                <div class="testimonial-content">
                                    <p>Prianka Mudelige is a dynamic leader and seasoned professional 
                                        in higher education administration, currently serving as the Director of Global Synergy Consultation 
                                        Services. With a deep understanding of the complexities of the education sector, Prianka is passionate 
                                        about fostering collaboration and innovation to enhance the student experience. As a working partner 
                                        with NAASCAMPUS, she leverages her expertise to drive initiatives aimed at advancing
                                         campus programs and services across North America. Prianka's strategic vision and commitment to
                                          excellence make her a valuable asset in empowering education professionals and driving positive change
                                           within the industry.</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimonial-bx">
                                <div class="testimonial-thumb">
                                    <img src="../../public/assets/Client/images/member/Vijithapala.jpg" alt="">
                                </div>
                                <div class="testimonial-info">
                                    <h5 class="name">Mr. K.H.Vijithapala</h5>
                                    <p>National Coordinator Naas Campus<br>Qualified Phamacist and Graduate Nursing Officer</p>
                                </div>
                                <div class="testimonial-content">
                                    <p>
                                        Mr. K.H. Vijithapala is a distinguished professional serving 
                                        as the National Coordinator for NAASCAMPUS. 
                                        With a background as a qualified pharmacist and graduate nursing officer, Mr. Vijithapala brings a unique blend of healthcare expertise and 
                                        administrative acumen to his role. He is deeply committed to advancing the quality of campus programs and services across North America, 
                                        leveraging his diverse skill set to promote excellence and innovation within the education sector. Mr. Vijithapala's leadership and dedication 
                                        make him a driving force in empowering education professionals and enhancing the student experience on campuses nationwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonials END -->

            <!-- Recent News -->
            <div class="section-area section-sp2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 heading-bx left">
                            <h2 class="title-head">Recent <span>News</span></h2>
                            <p>It is a long established fact that a reader will be distracted by the readable
                                content of a page</p>
                        </div>
                    </div>
                    <div class="recent-news-carousel owl-carousel owl-btn-1 col-12 p-lr0" >
                        <div class="item" v-for="n in news" :key="n.id">
                            <div class="recent-news">
                                <div class="action-box">
                                    <img src="../../public/assets/Client/images/blog/latest-blog/Opening.jpg" alt="">
                                </div>
                                <div class="info-bx">
                                    <ul class="media-post">
                                        <li><a href="#"><i class="fa fa-calendar"></i>{{ n.Date }}</a></li>
                                        <li><a href="#"><i class="fa fa-user"></i>{{ n.author }}</a></li>
                                    </ul>
                                    <h5 class="post-title"><a href="blog-details.html">{{ n.Title }}</a></h5>
                                    <p>{{ n.Description }}</p>
                                    <!-- <div class="post-extra">
                                        <a href="#" class="btn-link">READ MORE</a>
                                        <a href="#" class="comments-bx"><i class="fa fa-comments-o"></i>20
                                            Comment</a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- Recent News End -->

        </div>
        <!-- contact area END -->
    </div>
    <!-- Content END-->
    <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { db, getDocs, collection } from '@/includes/firebase'

export default {
    name: 'HomeView',
    components: {
        Header,
        Footer
    },
  data() {
    return {
      news: [] // Initialize an empty array to store fetched blogs
    }
  },
  async mounted() {
    const colRef = collection(db, 'news')
    try {
      const snapshot = await getDocs(colRef)
      snapshot.forEach(doc => {
        // Push each blog document into the blogs array
        this.news.push({ ...doc.data(), id: doc.id })
      })
    } catch (error) {
      console.error('Error fetching news:', error.message)
    }
	
  }
  
}
</script>